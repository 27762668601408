import React, { useEffect } from "react";
import { Button, Icon, Form, Grid } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, logout } from "../features/User/UserSlice";
import styles from "../styles/Login.module.css";
import "../styles/global.css";
import logo from "../logo.png";
import { useHistory } from "react-router-dom";
import { Alert } from "../components/ui.js";
export default function LoginPage() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  function onSubmit(event) {
    var data = event.target.elements;
    dispatch(
      loginUser({
        apogee: data.apogee.value,
        cin: data.cin.value,
        birthDate: data.birthDate.value,
      })
    );
  }
  useEffect(() => {
    // if (isError) {
    //   toast.error(errorMessage);
    //   dispatch(clearState());
    // }

    if (user.isLogged) {
      history.push("/profile");
    }
  }, [user.isLogged]);
  return (
    <div className="flex-center h100">
        <Form
          onSubmit={onSubmit}
          className={styles.loginForm}
          style={{
            background: "#FFFFFF",
            boxShadow: "0 10px 10px rgb(0 0 0 / 10%)",
            borderRadius: "8px",
          }}
        >
          <div style={{ textAlign: "center", marginBottom: "8px" }}>
            <img
              src={logo}
              alt="image"
              width="128px"
              height="128px"
              style={{ margin: "8px", textAligh: "center" }}
            />
          </div>
          <Form.Field>
            <label>N° APOGEE</label>
            <input placeholder="APOGEE" name="apogee" />
          </Form.Field>
          <Form.Field>
            <label>CIN</label>
            <input placeholder="CIN" name="cin" />
          </Form.Field>
          <Form.Field>
            <label>DATE DE NAISSANCE</label>
            <input placeholder="Exemple: 01/01/2001" name="birthDate" />
          </Form.Field>
          <Button
            color="violet"
            animated
            style={{ width: "100%" }}
            loading={user.isFetching ? true : false}
          >
            <Button.Content visible>SE CONNECTER</Button.Content>
            <Button.Content hidden>
              <Icon name="arrow right" />
            </Button.Content>
          </Button>
          <Alert enabled={user.isRejected} type="danger" message="N° Apogee ou CIN non reconnu."/>
        </Form>
</div>
  );
}
